export const pilliarData = [
    {
        name: "Qualité : Expertise, empathie et authenticité",
        description: "Ce qui fait qu'une conversation est utile, pertinente, agréable pour le client, qu'elle répond à ses attentes et bien plus encore.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20876.png",
        hubspotValue: "ci_p1",
        colorMain: "#128434",
        colorSecond: "#1BC74E",
        questions: [
            {
                name: "Lorsqu'une question est posée par messaging (NB : par messaging, nous entendons par chat, SMS et/ou via des applications de messaging) : ",
                hubspotValue: "ci_p1_q1",
                id: '1',
                percentProgress: '6%',
                answers: [
                    {
                        name: "Un chatbot répond à toutes les questions. Aucun humain n'est impliqué.",
                        score: "1"
                    },
                    {
                        name: "Les conseillers répondent à toutes les questions. Ils ne sont spécialisés sur aucun domaine en particulier.",
                        score: "2"
                    },
                    {
                        name: "Un chatbot accueille le client et répond aux questions fréquemment posées. Si nécessaire, un conseiller prend en charge la conversation.",
                        score: "3"
                    },
                    {
                        name: "Chaque question est traitée par le répondant le plus approprié (chatbot, conseiller ou expert) en fonction du sujet (support, conseils sur les produits, famille de produits, etc.).",
                        score: "4"
                    }
                ]
            },
            {
                name: "Lorsque vos clients posent une question par messaging liée à un produit ou un service particulier : ",
                hubspotValue: "ci_p1_q2",
                id: '2',
                percentProgress: '11%',
                answers: [
                    {
                        name: "Nos conseillers ou chatbots recherchent des informations uniquement dans notre catalogue de produits en ligne.",
                        score: "1"
                    },
                    {
                        name: "Nos conseillers recherchent des informations dans un catalogue de produits ou d'autres sources, comme le site web du fabricant.",
                        score: "2"
                    },
                    {
                        name: "Outre les informations sur les produits, nos conseillers peuvent demander une aide et une expertise supplémentaire en interne ou en externe.",
                        score: "3"
                    },
                    {
                        name: "En plus de répondre aux questions relatives aux produits, nos conseillers sont formés pour donner des informations supplémentaires et faire des recommandations de produits en fonction des besoins des clients.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Lorsque vous répondez à vos clients par messaging ",
                hubspotValue: "ci_p1_q3",
                id: '3',
                percentProgress: '17%',
                answers: [
                    {
                        name: "Les conseillers sont libres de répondre dans leur propre style. Nous ne leur fournissons pas de lignes directrices.",
                        score: "1"
                    },
                    {
                        name: " Il existe un ensemble de réponses préenregistrées qui doivent être utilisées. / Ou : Le seul répondant est un bot.",
                        score: "2"
                    },
                    {
                        name: "Il est recommandé aux conseillers d'utiliser nos réponses préenregistrées. Certains conseillers offrent leur propre avis et utilisent leur propre ton si nécessaire.",
                        score: "3"
                    },
                    {
                        name: "Outre les réponses préenregistrées, les conseillers sont formés pour partager leur propre expérience et trouver le bon ton tout en restant professionnels.",
                        score: "4"
                    }
                ]
            },

        ]
    },
    {
        name: "Scalabilité",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20875.png",
        hubspotValue: "ci_p2",
        colorMain: "#2958E5",
        colorSecond: "#25A2FA",
        questions: [
            {
                name: "Vos clients peuvent vous joindre par messaging :  ",
                hubspotValue: "ci_p2_q1",
                id: '4',
                percentProgress: '22%',
                answers: [
                    {
                        name: "Uniquement pendant les heures ouvrées.",
                        score: "1"
                    },
                    {
                        name: "Un chatbot leur répond à chaque fois qu'ils posent une question.",
                        score: "2"
                    },
                    {
                        name: "Nos conseillers répondent pendant les heures ouvrées et un chatbot répond en dehors des heures ouvrées.",
                        score: "3"
                    },
                    {
                        name: "Nos conseillers répondent pendant les heures ouvrées. Les clients peuvent laisser un message lorsque les conseillers ne sont pas disponibles ou discuter avec un chatbot. ",
                        score: "4"
                    }
                ]
            },
            {
                name: "Vos clients peuvent initier avec votre marque des conversations par messaging en temps réel ",
                hubspotValue: "ci_p2_q2",
                id: '5',
                percentProgress: '28%',
                answers: [
                    {
                        name: "Pendant les heures ouvrées. Lorsque les conseillers ne sont pas immédiatement disponibles, nos clients sont placés dans la file d'attente.",
                        score: "1"
                    },
                    {
                        name: "Pendant les heures ouvrées et lorsque les conseillers sont immédiatement disponibles. Si un conseiller n'est pas immédiatement disponible, nous nous assurons que le client n'attende pas plus de 30 secondes dans la file d'attente.",
                        score: "2"
                    },
                    {
                        name: "24/7. Nos conseillers répondent pendant les heures ouvrées. En dehors des heures ouvrées, les chatbots répondent autant que possible.",
                        score: "3"
                    },
                    {
                        name: "24/7. Nos conseillers répondent pendant les heures ouvrées. En dehors de celles-ci, des experts indépendants prennent le relais pour assurer une disponibilité 24 heures sur 24, 7 jours sur 7. En outre, les clients peuvent discuter avec un chatbot.",
                        score: "4"
                    }
                ]
            },
            {
                name: "La vision de votre entreprise concernant les chatbots :  ",
                hubspotValue: "ci_p2_q3",
                id: '6',
                percentProgress: '33%',
                answers: [
                    {
                        name: "Les bots n'ont pas de valeur ajoutée dans la réponse apportée à nos clients.",
                        score: "1"
                    },
                    {
                        name: "Les chatbots remplacent les conseillers. Ils gèrent toutes les conversations par messaging avec nos clients.  ",
                        score: "2"
                    },
                    {
                        name: "Les chatbots remplacent autant que possible les conseillers. Ils gèrent toutes les conversations entrantes et, si nécessaire, ils les transfèrent aux conseillers.",
                        score: "3"
                    },
                    {
                        name: "Les chatbots complètent les conseillers. Ils accueillent les clients et qualifient leurs besoins si nécessaire, avant de les mettre en relation avec un conseiller.",
                        score: "4"
                    }
                ]
            },

        ]
    },
    {
        name: "Stratégie & Pilotage",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20871.png",
        hubspotValue: "ci_p3",
        colorMain: "#F7AA4D",
        colorSecond: "#FFCD40",
        questions: [
            {
                name: "Quelle est la place du messaging dans le plan stratégique de votre entreprise ? ",
                hubspotValue: "ci_p3_q1",
                id: '7',
                percentProgress: '39%',
                answers: [
                    {
                        name: "Nous avons lancé le messaging parce que nos concurrents le font.",
                        score: "1"
                    },
                    {
                        name: "Cela fait partie de nos priorités en matière d'innovation. Le messaging et les chatbots sont partout. Nous avons lancé le messaging pour rester à la pointe de l'innovation.",
                        score: "2"
                    },
                    {
                        name: "Cela fait partie de nos priorités en matière d'innovation. Notre stratégie conversationnelle génère de la valeur, un chiffre d'affaires supplémentaire et réduit les coûts opérationnels.",
                        score: "3"
                    },
                    {
                        name: "Notre stratégie conversationnelle fait partie de nos priorités stratégiques. Elle fait partie de notre ADN et soutient les transformations au sein de notre entreprise.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Comment les canaux de messaging sont-ils pilotés au sein de votre organisation ? ",
                hubspotValue: "ci_p3_q2",
                id: '8',
                percentProgress: '44%',
                answers: [
                    {
                        name: "Il n'y a pas d'équipe dédiée. La Directrice/le Directeur de la Relation Client n'a pas d'objectif spécifique en matière de messaging.  ",
                        score: "1"
                    },
                    {
                        name: "Il existe une équipe dédiée au messaging. La Directrice/le Directeur de la Relation Client accorde une attention croissante au messaging.  ",
                        score: "2"
                    },
                    {
                        name: "Il existe une équipe dédiée au messaging. Ses résultats sont communiqués à la Directrice/au Directeur de la Relation Client ou à des postes similaires.",
                        score: "3"
                    },
                    {
                        name: "Notre organisation est centrée sur le messaging. La Directrice/le Directeur de la Relation Client se concentre principalement sur le messaging.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Quels indicateurs suivez-vous pour mesurer la performance de votre stratégie conversationnelle ?",
                hubspotValue: "ci_p3_q3",
                id: '9',
                percentProgress: '50%',
                answers: [
                    {
                        name: "Des indicateurs de productivité et de qualité ainsi que de satisfaction client.  ",
                        score: "1"
                    },
                    {
                        name: "Des indicateurs de productivité et de qualité, de satisfaction client, ainsi que des indicateurs de performance commerciale tels que le chiffre d'affaires après conversation, le taux de conversion et le panier moyen.",
                        score: "2"
                    },
                    {
                        name: "Des indicateurs de productivité, de qualité et de performance commerciale, ainsi que d'optimisation des coûts opérationnels (par exemple, réduction des coûts du Service Client).",
                        score: "3"
                    },
                    {
                        name: "En plus des indicateurs de productivité, de qualité, de performance commerciale et d'optimisation des coûts opérationnels, des KPIs de long terme comme le taux de réachat ou la CLV.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Recueillez-vous des insights et des retours clients sur et via votre stratégie conversationnelle ?",
                hubspotValue: "ci_p3_q4",
                id: '10',
                percentProgress: '56%',
                answers: [
                    {
                        name: "Le messaging est utilisé uniquement en support client.",
                        score: "1"
                    },
                    {
                        name: " Nous testons différentes stratégies conversationnelles et comparons leur impact.",
                        score: "2"
                    },
                    {
                        name: "Nous analysons le parcours client pour identifier les frictions et ajuster notre stratégie conversationnelle en conséquence. ",
                        score: "3"
                    },
                    {
                        name: "Nous analysons les conversations et en tirons des enseignements afin d'améliorer les parcours, les process et notre offre.",
                        score: "4"
                    }
                ]
            }

        ]
    },
    {
        name: "Engagement et personnalisation",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(5).png",
        hubspotValue: "ci_p4",
        colorMain: "#128434",
        colorSecond: "#1BC74E",
        questions: [
            {
                name: "Vos clients peuvent communiquer avec vous par messaging...",
                hubspotValue: "ci_p4_q1",
                id: '11',
                percentProgress: '61%',
                answers: [
                    {
                        name: "Sur une seule page web (par exemple, Contactez-nous). ",
                        score: "1"
                    },
                    {
                        name: "Sur les pages d'assistance (par exemple, FAQ) et leur espace personnel.",
                        score: "2"
                    },
                    {
                        name: "Sur les pages produits et le parcours client afin d'optimiser le chiffre d'affaires.",
                        score: "3"
                    },
                    {
                        name: "Sur toutes les pages de notre site web.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Vous communiquez avec vos clients par messaging....",
                hubspotValue: "ci_p4_q2",
                id: '12',
                percentProgress: '67%',
                answers: [
                    {
                        name: "lorsqu'ils nous contactent.",
                        score: "1"
                    },
                    {
                        name: "après un certain temps sur notre site web.",
                        score: "2"
                    },
                    {
                        name: "de manière proactive, lors d’étapes stratégiques du parcours client, ou via un ciblage comportemental. ",
                        score: "3"
                    },
                    {
                        name: "Lorsque le client nous contacte ou de manière proactive, lorsque nous identifions un besoin potentiel de conseil.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Vos clients peuvent initier une conversation par messaging...",
                hubspotValue: "ci_p4_q3",
                id: '13',
                percentProgress: '72%',
                answers: [
                    {
                        name: "uniquement sur notre site web desktop.",
                        score: "1"
                    },
                    {
                        name: "sur notre site web et sur certaines parties de notre site mobile.",
                        score: "2"
                    },
                    {
                        name: "à la fois sur notre site web mobile et sur notre site web desktop.",
                        score: "3"
                    },
                    {
                        name: "sur notre site web, sur tous les appareils et sur au moins une plateforme de messaging (par exemple SMS, WhatsApp, Facebook Messenger...).",
                        score: "4"
                    }
                ]
            }
        ]
    },
    {
        name: "Messaging riche et facile à utiliser",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(6).png",
        hubspotValue: "ci_p5",
        colorMain: "#2958E5",
        colorSecond: "#25A2FA",
        questions: [
            {
                name: " Comment le messaging s'intègre-t-il dans votre expérience web ? ",
                hubspotValue: "ci_p5_q1",
                id: '14',
                percentProgress: '78%',
                answers: [
                    {
                        name: "Il se distingue. Le messaging apparaît dans une fenêtre séparée.",
                        score: "1"
                    },
                    {
                        name: "La chatbox s'intègre à la page web, mais nous ne pouvons pas la personnaliser à notre image de marque (couleur, police, ton).",
                        score: "2"
                    },
                    {
                        name: "Le messaging s'intègre totalement à notre site web car nous voulons qu'il fasse partie de notre UX.",
                        score: "3"
                    },
                    {
                        name: "Il s'intègre totalement dans notre site web. En outre, nos conseillers peuvent visualiser la navigation des clients sur notre site pour mieux les aider.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Comment le messaging s'intègre-t-il dans votre expérience mobile ?",
                hubspotValue: "ci_p5_q2",
                id: '15',
                percentProgress: '83%',
                answers: [
                    {
                        name: "La chatbox s’ouvre dans un autre onglet. Nous ne voulons pas que la chatbox interfère avec la navigation.",
                        score: "1"
                    },
                    {
                        name: "La chatbox s'ouvre sur la même page. Le client peut minimiser la chatbox pour continuer à naviguer sur le contenu du site.",
                        score: "2"
                    },
                    {
                        name: "La chatbox s'ouvre sur la même page. Une notification s'affiche lorsqu'un nouveau message est reçu alors que la boîte de dialogue est réduite / fermée.",
                        score: "3"
                    },
                    {
                        name: "La chatbox s'ouvre sur la même page. Le client est averti lorsqu'il reçoit une réponse même après avoir quitté le site.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Quel type de contenu peut être partagé dans les conversations avec vos clients ?",
                hubspotValue: "ci_p5_q3",
                id: '16',
                percentProgress: '89%',
                answers: [
                    {
                        name: "Nos conversations sont uniquement textuelles. Nous ne supportons pas les emojis, les hyperliens, les images ou les documents.",
                        score: "1"
                    },
                    {
                        name: "Nous supportons le texte riche : nous pouvons envoyer et recevoir des emojis et des liens.",
                        score: "2"
                    },
                    {
                        name: "Nous supportons le texte riche, et nous pouvons également envoyer et recevoir des documents et des images.",
                        score: "3"
                    },
                    {
                        name: "Nous supportons le texte riche, les documents et les images. En outre, nous pouvons passer du messaging à l'appel vocal ou vidéo.",
                        score: "4"
                    }
                ]
            }
        ]
    },
    {
        name: "Fluidité & omnicanalité",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(7).png",
        hubspotValue: "ci_p6",
        colorMain: "#F7AA4D",
        colorSecond: "#FFCD40",
        questions: [
            {
                name: "Lorsque vos clients vous recontactent par messaging... ",
                hubspotValue: "ci_p6_q1",
                id: '17',
                percentProgress: '94%',
                answers: [
                    {
                        name: "le conseiller n'a pas accès aux conversations passées.",
                        score: "1"
                    },
                    {
                        name: "le conseiller peut avoir accès aux conversations passées parallèlement à la conversation en cours.",
                        score: "2"
                    },
                    {
                        name: "les conversations passées sont automatiquement affichées dans l'interface du conseiller lorsque la conversation commence.",
                        score: "3"
                    },
                    {
                        name: "les conseillers et les clients peuvent reprendre la conversation qu’ils ont quittée de manière totalement fluide et transparente. ",
                        score: "4"
                    }
                ]
            },
            {
                name: "Vos clients peuvent vous joindre par..",
                hubspotValue: "ci_p6_q2",
                id: '18',
                percentProgress: '97%',
                answers: [
                    {
                        name: "messaging sur votre site web.",
                        score: "1"
                    },
                    {
                        name: "le messaging et un autre canal, tel que l'appel vidéo, le web callback, les médias sociaux ou les applications de messaging tierces (WhatsApp, ABC, Facebook Messenger...).",
                        score: "2"
                    },
                    {
                        name: "le messaging et au moins 5 autres canaux tels que l'appel vidéo, le web callback, les médias sociaux ou les applications de messaging tierces (WhatsApp, ABC, Facebook Messenger...). ",
                        score: "3"
                    },
                    {
                        name: "au moins 5 canaux différents, mais aussi d'autres innovations permettant d'engager des conversations par messaging, telles que les QR codes ou les fonctionnalités de transfert d’appel vers le messaging.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Dans quelle mesure votre stratégie conversationnelle est-elle au cœur de votre transformation omnicanale ?",
                hubspotValue: "ci_p6_q3",
                id: '19',
                percentProgress: '100%',
                answers: [
                    {
                        name: "Nous utilisons le messaging uniquement dans le cadre de notre expérience digitale.",
                        score: "1"
                    },
                    {
                        name: "Nous donnons parfois des informations sur la disponibilité de nos produits et d’autres informations sur nos points de vente physiques par messaging.",
                        score: "2"
                    },
                    {
                        name: "Nous encourageons l'utilisation du messaging dans nos magasins et nos conseillers s’en servent pour booster les ventes en magasin. ",
                        score: "3"
                    },
                    {
                        name: "Nous utilisons le messaging pour booster les ventes en ligne, en magasin et élever l'expérience client. Les vendeurs des magasins peuvent répondre aux visiteurs en ligne.",
                        score: "4"
                    }
                ]
            },
            {
                name: "",
                hubspotValue: "ci_p6_q3",
                id: '20',
                percentProgress: '100%',
                answers: [
                    {
                        name: "",
                        score: "1"
                    },
                    {
                        name: "",
                        score: "2"
                    },
                    {
                        name: "",
                        score: "3"
                    },
                    {
                        name: "",
                        score: "4"
                    }
                ]
            }

        ]
    }

  ];