import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import CiQuizzFr from "../components/conversational-index/france/ci-questionnaire/ci-quizz";

import SEO from "../components/seo";

class QuestionnairePage extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index : Évaluez la maturité conversationnelle de votre entreprise." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(2).png" lang="fr" description="En réalisant la version en ligne de cet audit, obtenez un aperçu objectif de votre maturité conversationnelle en moins de 10 minutes. Recevez une analyse présentant vos forces et vos axes d’amélioration, ainsi que votre situation par rapport à votre secteur."/>
                <CiQuizzFr/>
            </>
        )
    }

}

export default QuestionnairePage