import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, Toast } from 'react-bootstrap';

import CiQuizzStyle from "./ci-quizz.module.scss";
import { pilliarData } from "./../../../../datas/datas-fr";
import { navigate } from "gatsby";


const CiQuizzFr = (props) => {

    const [show, setShow] = useState(false);

    const [indexQuestion, setIndexQuestion] = useState(0);
    const [indexPillier, setIndexPillier] = useState(0);

    const [nullAnswer, setNullAnswer] = useState(0);

    const [email, setEmail] = useState("");
    const [industrie, setIndustrie] = useState("");
    const [website, setWebsite] = useState("");
    const [job, setJob] = useState("");

    const handleEmail = (event) => setEmail(event.target.value);
    const handleIndustrie = (event) => setIndustrie(event.target.value);
    const handleWebsite = (event) => setWebsite(event.target.value);
    const handleJob = (event) => setJob(event.target.value);

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const [showToastDisclaimer, setShowToastDisclaimer] = useState(true);
    const toggleToastDisclaimer = () => setShowToastDisclaimer(!showToastDisclaimer);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [screenPreQualification, setScreenQualification] = useState(true);

    const handlePreQualification = () => setScreenQualification(false);

    const [resultatsArray, updateResultatsArray] = useState([]);

    const resettingRef = useRef(false);

    const increasePillier = () => {
        setIndexPillier(indexPillier + 1)
    }

    const decreasePillier = () => {
        setIndexPillier(indexPillier - 1)
    }

    const rollBack = () => {
        if(indexQuestion == 0){

            if(indexPillier == 3)
            {
                setIndexQuestion(3);
            } else 
            {
                setIndexQuestion(2);
            }

            setIndexPillier(indexPillier - 1);

        } else{

            setIndexQuestion(indexQuestion - 1);
        }

        updateResultatsArray(resultatsArray.filter((element, index) => index < resultatsArray.length - 1));
    }

    const increaseQuestion = () => {

        if((indexPillier + 1 == 3) && ((indexQuestion + 1) == 3)){
            
            setIndexQuestion(indexQuestion + 1);

        } else if((indexQuestion + 1) == 3 || ((indexPillier + 1 == 3) && ((indexQuestion + 1) == 4))) {
            
            increasePillier()
            setIndexQuestion(0);

        } else{

            setIndexQuestion(indexQuestion + 1);

        }
        
    }

    const increaseLastQuestion = () => {
        setIndexQuestion(indexQuestion + 1);
    }


    const addAnswer = (id) => {

        resettingRef.current = true;

        if(id == 0){
            setNullAnswer(nullAnswer+1)
            
            if(nullAnswer >= 3){
                setShow(true);
            }
            
            updateResultatsArray(resultatsArray.concat(id));

            if(pilliarData[indexPillier].questions[indexQuestion].id == 19){
                increaseLastQuestion();
            } else{
                increaseQuestion();
            }
            
        } else {
            updateResultatsArray(resultatsArray.concat(id));

            if(pilliarData[indexPillier].questions[indexQuestion].id == 19){
                increaseLastQuestion();
            } else{
                increaseQuestion();
            }
        }

    }

    useEffect(() => {

        console.log("immutable data changed");
        console.log(resultatsArray);

        if(resettingRef.current){
          resettingRef.current = false;

            console.log(resultatsArray.length);

            
            if(pilliarData[indexPillier].questions[indexQuestion].id == 20){
                navigate(
                    "/fr/resultat",
                    {
                        state: { resultatsArray }
                    }
                );
            }
          
        }
    },[resultatsArray])


    const sendDataToHubspot = () => {
        console.log("le mail est : " + email);
        console.log("l'industrie est : " + industrie);
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"fields":[{"name":"email","value":email},{"name":"consent_checkbox","value":"true"},{"name":"jobtitle","value":job},{"name":"website","value":website},{"name":"ci_industry","value":industrie}],"context":{"pageUri":"www.conversational-index.com/fr/","pageName":"Conversational Index - Landing Page - Form menu"}}),
            redirect: 'follow'
        };
        fetch('https://api.hsforms.com/submissions/v3/integration/submit/403167/1f945562-c2ee-4649-b6ed-c592b3d41b02', requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .then(handleClose)
        .then(toggleToast)
        .catch(error => console.log('error', error));
    }


    return (
        <>

            <div
                style={{
                    position: 'fixed',
                    top: 10,
                    left: 10,
                    zIndex: 99
                }}
            >

                <Toast show={showToastDisclaimer} onClose={toggleToastDisclaimer}>
                    <Toast.Header>
                        <strong>Disclaimer</strong>
                    </Toast.Header>
                    <Toast.Body>
                        “En utilisant ce service, vous vous engagez à ne pas tenter d'obtenir la méthode et la réflexion ci-après proposées par iAdvize pour un usage commercial contraire aux intérêts d'iAdvize. Ce service n’a vocation qu’à être utilisé que comme un moyen d'évaluation du positionnement des entreprises par rapport à leurs concurrents dans le domaine des performances conversationnelles, et est protégé par plusieurs lois américaines et européennes. iAdvize et ses filiales se réservent le droit de se défendre contre tout abus, violation et/ou détournement du CONVERSATIONAL MATURITY INDEX. iAdvize et ses filiales ne sont en aucun cas responsables de l'effet de cet indice sur la réputation de l'utilisateur ou d'un tiers, quel qu'il soit.”
                    </Toast.Body>
                </Toast>


            </div>

            <section className={ CiQuizzStyle.preQualification } style={{ display: screenPreQualification ? 'block': 'none'}}>
                <div className={ CiQuizzStyle.inner }>
                    
                    <div className={ CiQuizzStyle.idzLogo }>
                        powered by <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Large.png" alt=""/>
                    </div>

                    <div className={ CiQuizzStyle.questionQualification }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt="" />
                        <p>Votre stratégie conversationnelle soutient-elle réellement vos enjeux de croissance ? Les questions de cet index sont conçues pour évaluer précisément la pertinence et  l’efficacité de votre stratégie conversationnelle, et vous apporter des solutions. Répondez en toute transparence pour obtenir les meilleurs résultats.</p>
                        <h1>
                        Vos clients peuvent-ils poser des questions sur votre site web ?
                            <span>(votre site mobile ou votre application par chat et/ou via des applications de messaging)</span>
                        </h1>
                        <div>
                            <button onClick={ handlePreQualification }>Oui</button>
                            <button onClick={ handleShow }>Non</button>
                        </div>
                    </div>

                    <img id={ CiQuizzStyle.logoFooter } src="https://info.iadvize.com/hubfs/CI%20-%202021/Title.png"></img>
                </div>

                
            </section>

            <section className={ CiQuizzStyle.quizz } style={{ display: pilliarData[indexPillier].questions[indexQuestion].id != 20 && !screenPreQualification ? 'block': 'none'}}>
        <div className={ CiQuizzStyle.inner }>
            <div className={ CiQuizzStyle.alpha }>
                <div className={ CiQuizzStyle.top } style={{background: pilliarData[indexPillier].colorMain }}>
                    <h2>
                        { pilliarData[indexPillier].name } 
                    </h2>
{/*                     <div className={ CiQuizzStyle.legend }>
                        { pilliarData[indexPillier].description } 
                    </div> */}
                </div>    
                <div className={ CiQuizzStyle.bottom } style={{background: pilliarData[indexPillier].colorSecond }}>
                    <img src={ pilliarData[indexPillier].illustration }></img>
                </div>    
            </div>
            <div className={ CiQuizzStyle.beta }>
                <div className={ CiQuizzStyle.mordor }>

                    <div className={ CiQuizzStyle.top}>
                        <span>Question { pilliarData[indexPillier].questions[indexQuestion].id }</span>
                        <div className={ CiQuizzStyle.categorie }>
                            { pilliarData[indexPillier].name }
                        </div>
                        <div className={ CiQuizzStyle.question }>
                            { pilliarData[indexPillier].questions[indexQuestion].name }
                        </div>
                    </div>

                    <div className={ CiQuizzStyle.bottom}>
                        <div className={ CiQuizzStyle.answers }>
                            <button onClick={() => addAnswer(1)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[0].name }</button>                        
                            <button onClick={() => addAnswer(2)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[1].name }</button>                        
                            <button onClick={() => addAnswer(3)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[2].name }</button>                        
                            <button onClick={() => addAnswer(4)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[3].name }</button>                          
                        </div>
                        <button onClick={() => addAnswer(0)} className={ CiQuizzStyle.pass } href="#">Je ne sais pas</button>
                        <button onClick={() => rollBack()} className={ CiQuizzStyle.previous } disabled={ indexPillier == 0 && indexQuestion == 0 } href="#"> ← Question précédente</button>
                    </div>

                </div>
            </div>

            <div className={ CiQuizzStyle.progression }>
                <div className={ CiQuizzStyle.set }>
                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt="" />
                    <div className={ CiQuizzStyle.right }>

                        <div className={ CiQuizzStyle.barInner }>
                            <div className={ CiQuizzStyle.bar }>
                                <div className={ CiQuizzStyle.follow } style={{width: pilliarData[indexPillier].questions[indexQuestion].percentProgress }}>
                                </div>
                            </div>

                            <span>
                                { pilliarData[indexPillier].questions[indexQuestion].id }/19
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <div
            style={{
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 99
            }}
        >
            <Toast show={showToast} onClose={toggleToast} delay={5000} autohide>
                <Toast.Header>
                    <strong>Merci !</strong>
                </Toast.Header>
                <Toast.Body>Vous allez recevoir un e-mail avec votre secteur ainsi que des axes d'amélioration.</Toast.Body>
            </Toast>

        </div>

        <Modal  
        className="modalMain"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        >
        <Modal.Body className="modalBody">
            <img onClick={handleClose} src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20865.png"></img>
            <h3 className="modalTitle">Confiez</h3>
            <p className="modalLegend">votre évaluation à nos équipes</p>

            <div className="formInner">
                <form action="" method="get" className="formLate">

                    <div className="formSet">
                        <span>Industrie*</span>
                        <select id="industries" name="industries" value={industrie} onChange={handleIndustrie} required>
                            <option value="Retail & CPG">Distribution et produits de grande consommation</option>
                            <option value="Finance - Health Insurance">Finance, santé & Assurance</option>
                            <option value="Automotive">Automobile</option>
                            <option value="Telco & Consumer Services">Télécom & services</option>
                            <option value="Travel & Tourism">Tourisme & Voyages</option>
                            <option value="Other">Autres</option>
                        </select>
                    </div>

                    <div className="formSet">
                        <span>Profession*</span>
                        <input type="text" name="job" id="job" value={job} onChange={handleJob} placeholder="Nom du poste" required/>
                    </div>

                    <div className="formSet">
                        <span>Website*</span>
                        <input type="text" name="website" id="website" value={website} onChange={handleWebsite} placeholder="website" required/>
                    </div>

                    <div className="formSet">
                        <span>Adresse email*</span>
                        <input type="email" name="email" id="email" value={email} onChange={handleEmail} placeholder="Email professionnel" required/>
                    </div>

                    <div className="formSet">
                        <div className="rgpd">
                            <input type="checkbox" id="scales" name="scales" required/>
                            <label>J’ai lu et accepte la <a href="https://privacy.iadvize.com/fr/">politique d’iAdvize en matière de protection des données à caractère personnel</a></label>
                        </div>
                    </div>

                    <div className="formSet">
                        <Button id="goToLead" className="ctaFinal" onClick={sendDataToHubspot} disabled={!email || !website || !industrie }>
                            Sollicitez l'évaluation personnalisée
                        </Button>
                    </div>

                </form>
            </div>

        </Modal.Body>
        </Modal>
        </>
    )

}

export default CiQuizzFr

